/**
 * Gets the query params from the current window. Useful to get the shop domain
 */
export const getWindowQueryParams = () => (
  (window.location.search && JSON.parse('{"' + window.location.search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => {
      return key === '' ? value : decodeURIComponent(value)
    }
  )) || {}
)

/**
 * Get the shop domain defined by the window query
 */
export const getShopDomain = () => {
  const queryParams = getWindowQueryParams()
  if (queryParams && queryParams.shop)
    return queryParams.shop
}

/**
 * Get the shop host defined by the window query
 * Ref: https://shopify.dev/apps/tools/app-bridge/migrating
 */
 export const getShopHost = () => {
  const queryParams = getWindowQueryParams()
  if (queryParams && queryParams.host)
    return queryParams.host
}

/**
 * Get value or querystring parameter
 * @param {*} param
 */
export const getUrlParameter = (param) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}