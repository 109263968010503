import { getShopDomain } from './../services/window'

export const _hipleeApiEndpoint = process.env.TT_APP_HIPLEE_API
export const _frankieAppName = process.env.TT_APP_APPNAME
export const _frankieScript = process.env.TT_APP_HIPLEE_API
export const _shopifyApiKey = process.env.TT_APP_SHOPIFY_API_KEY
export const _frankieAppHost = `https://${_frankieScript}`

const frankieAppHomepage = () => {
  const shopDomain = getShopDomain()
  if (shopDomain)
    return `https://${shopDomain}${process.env.TT_APP_HOMEPAGE}`

  return ''
}
export const _frankieAppHomepage = frankieAppHomepage()
export const _frankieAppModal = `${_frankieAppHost}/modal.html`
export const _frankieOnboardingImages = `${_frankieAppHost}/onboarding`

export const _fullStory = process.env.TT_APP_FULLSTORY_ORG
export const _fbpixel = process.env.TT_APP_FACEBOOK_PIXEL
export const _gtm = process.env.TT_APP_GTM