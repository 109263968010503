import React, { Component } from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { Page, Card, Layout, TextContainer, Heading, List, Subheading, Icon, Stack } from '@shopify/polaris'
import { DeleteMajorMonotone, AddMajorMonotone } from '@shopify/polaris-icons'

class LearnMore extends Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    // something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    // something
  }

  render () {
    return (
        <Page
            title="Introduction"
        >
            <Layout >
                <Layout.Section >
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>My Timetable App</Heading>
                        <p>
                        Create and publish a schedule on a separate page in your store using the My Timetable App. 
                        The app produces a snapshot of your offerings in a table format that can be included in your store menus and navigation.
                        Make it easier for your shoppers to find and buy your products & services.</p>
                    </TextContainer>
                </Card>
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>Getting Started</Heading>
                        <p>This app enables you to create a table in a Shopify page with links to your product / service.</p>
                        <p>You can add as many rows as you like and leave rows or columns unfilled if needed (to create gaps).</p>
                        <p>Please note that leaving the product / service column empty emphasises the first column.</p>
                        <Subheading>Follow these steps to create your initial table</Subheading>
                        <List type="number">
                            <List.Item>Adjust column headings to suit your needs</List.Item>
                            <List.Item>
                                <Stack>
                                    <TextContainer>
                                        <Icon source={AddMajorMonotone} />
                                    </TextContainer>
                                    <TextContainer>
                                        to create a new row (below the current row)
                                    </TextContainer>
                                </Stack>
                            </List.Item>
                            <List.Item>Add the time (or other attribute) of your product / service in the first column</List.Item>
                            <List.Item>Select a product or service</List.Item>
                            <List.Item>
                                <Stack>
                                    <TextContainer>
                                        <Icon source={DeleteMajorMonotone} />
                                    </TextContainer>
                                    <TextContainer>
                                        to remove a row
                                    </TextContainer>
                                </Stack>
                            </List.Item>
                            <List.Item>Repeat these steps to create a full schedule</List.Item>
                            <List.Item>Click Save when you are done</List.Item>
                        </List>
                    </TextContainer>
                </Card>
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>Final Steps</Heading>
                        <List>
                            <List.Item>Use view page to see your table (page could be cached in the browser, refresh or use incognito)</List.Item>
                            <List.Item>Add your schedule to your main navigation, menu and footer as you do for any page</List.Item>
                        </List>
                    </TextContainer>
                </Card>
                </Layout.Section>
            </Layout>
        </Page>      
    )
  }

}

export default withStyles(globalStyles)(LearnMore)