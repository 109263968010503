import { SET_SCHEDULE, FETCH_SCHEDULE, SAVE_SCHEDULE } from '../actions/schedule'

const initialState = {
  isLoading: undefined,
  unsavedChanges: false,
  data: undefined
}

export const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULE:
      return {
        ...state,
        data: { ...action.payload.data},
        isLoading: false,
        unsavedChanges: true
      }
    case FETCH_SCHEDULE:
      return {
        ...state,
        data: action.payload.data,
        isLoading: action.payload.isLoading,
        unsavedChanges: false,
        error: action.payload.status === 'error' ? action.payload.error : null
      }
    case SAVE_SCHEDULE:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          unsavedChanges: action.payload.unsavedChanges,
          error: action.payload.status === 'error' ? action.payload.error : null,
          saveFlag: true
        }
    default:
      return state
  }
}