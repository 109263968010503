/**
 * Utility class to make all calls to Hiplee API.
 * Doesn't have any logic, just gets the params, creates the payload and sends the request.
 *
 * If this service starts to get bigger we can think about refactoring then.
 *
 * TODO: get token from somewhere else, don't require it as a parameter every time we make a call
 */
import { _hipleeApiEndpoint } from './../_constants/globals'

/**
 * Query string params passed from Shopify to the "redirectToCallbackRoute" lambda to the s3 url
 * are used here to authenticate with our API and get a JWT.
 * This JWT can be used to authorize future requests to our API.
 *
 * @param {*} authParams
 */
const getAuthToken = authParams => {
  return window.fetch(`https://${_hipleeApiEndpoint}/utils/authenticate${authParams}`)
}

/**
 * For now only gets the shop info if it exists, doesn't need billing info
 * but we haven't change the lambda name
 * @param {*} token
 */
const getMerchant = token => {
  if (!token)
    return

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'hiplee-jwt-auth-token': token
    }
  }

  return window.fetch(`https://${_hipleeApiEndpoint}/utils/merchant`, options)
}

/**
 * Saves flags in merchant DB. For now only promo code
 * @param {*} token
 * @param {*} key
 * @param {*} value
 */
const putMerchant = (token, key, value) => {
  if (!token)
    return

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'hiplee-jwt-auth-token': token
    },
    body: JSON.stringify({
      type: 'data',
      key: key,
      data: value
    })
  }

  return window.fetch(`https://${_hipleeApiEndpoint}/utils/merchant`, options)
}

/**
 * Saves schedule
 * @param {*} token
 * @param {*} payload (pageId, ttData, ttStyle, publish)
 */
const putSchedule = (token, payload) => {
  const options = {
    method: 'POST',
    headers: {
      'hiplee-jwt-auth-token': token
    },
    body: JSON.stringify({
      pageId : payload.pageId,
      pageTitle : payload.pageTitle,
      ttData : payload.ttData,
      ttStyle : payload.ttStyle,
      published : payload.published
    })
  }
  return window.fetch(`https://${_hipleeApiEndpoint}/service/schedule`, options)
}

/**
 * Get schedule
 *
 * @param {*} token
 * @param {*} from timestamp
 * @param {*} to timestamp
 */
const getSchedule = (token) => {
  const options = {
    method: 'GET',
    headers: {
      'hiplee-jwt-auth-token': token
    }
  }

  return window.fetch(`https://${_hipleeApiEndpoint}/service/schedule`, options)
}

export default {
  getAuthToken,
  getMerchant,
  putMerchant,
  getSchedule,
  putSchedule
}