import React from 'react'
import { Button, FormLayout, Stack, ButtonGroup, Card, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { DeleteMajorMonotone, AddMajorMonotone } from '@shopify/polaris-icons'

const ScheduleSkeleton = () => (
  <SkeletonPage title='Manage Schedule Table' >
    <Card title="" sectioned>
      <Card.Section title="Page Title">
        <SkeletonDisplayText size="small" />
      </Card.Section>
      <Card.Section title="Table Heading">
        <FormLayout.Group>
          <SkeletonDisplayText size="small" />
          <SkeletonDisplayText size="small" />
        </FormLayout.Group>
      </Card.Section>
      <Card.Section title="Table Details">
        <FormLayout.Group>
          <SkeletonDisplayText size="small" />
          <Stack>
            <Stack.Item fill>
              <SkeletonDisplayText size="small" />
            </Stack.Item>
            <Stack.Item>
              <Button icon = { AddMajorMonotone }></Button>
            </Stack.Item>
            <Stack.Item>
              <Button icon = { DeleteMajorMonotone }></Button>
            </Stack.Item>
          </Stack>
        </FormLayout.Group>
        <FormLayout.Group>
          <SkeletonDisplayText size="small" />
          <Stack>
            <Stack.Item fill>
              <SkeletonDisplayText size="small" />
            </Stack.Item>
            <Stack.Item>
              <Button icon = { AddMajorMonotone }></Button>
            </Stack.Item>
            <Stack.Item>
              <Button icon = { DeleteMajorMonotone }></Button>
            </Stack.Item>
          </Stack>
        </FormLayout.Group>
      </Card.Section>
      <Stack distribution='trailing'>
        <ButtonGroup>
          <Button> Add More </Button>
          <Button primary> Save </Button>
        </ButtonGroup>
      </Stack>
    </Card>
  </SkeletonPage>
)

export default ScheduleSkeleton