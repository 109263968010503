import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import store from '../../_redux/store'
import { setSchedule, saveSchedule } from '../../_redux/actions/schedule'
import { getShopDomain } from '../../services/window'
import { Button, Page, Form, FormLayout, TextField, Stack, Loading, Frame, ButtonGroup, Card, Link } from '@shopify/polaris'
import { ResourcePicker } from '@shopify/app-bridge-react'
import { DeleteMajorMonotone, AddMajorMonotone } from '@shopify/polaris-icons'
import ScheduleSkeleton from './ScheduleSkeleton'

class Schedule extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      schedule: null,
      tableHeader: null,
      tableData: null,
      pageTitle: null,
      pickerOpen: false,
      selectedRow: 1,
      autofocusRow: 1,
      storeDomain: this.props.storeDomain,
      saveFlag : false,
      shop: getShopDomain()
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    const {tableHeader} = this.state
    const storeData = store.getState().schedule
    if (storeData && storeData.data && !tableHeader) {
      const ttData = JSON.parse(storeData.data.ttData.value)
      this.setState({loading: false})
      this.setState({pageTitle: storeData.data.pageTitle})
      this.setState({tableHeader: ttData.header})
      this.setState({tableData: ttData.items})
      this.setState({autofocusRow: ttData.items.length +1})
      this.setState({schedule: storeData})
    }
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    if (prevProps.schedule != this.props.schedule && this.props.schedule && !this.props.schedule.isLoading) {
      const ttData = JSON.parse(this.props.schedule.data.ttData.value)
      this.setState({loading: false})
      this.setState({pageTitle: this.props.schedule.data.pageTitle})
      this.setState({tableHeader: ttData.header})
      this.setState({tableData: ttData.items})
      this.setState({autofocusRow: ttData.items.length +1})
      this.setState({schedule: this.props.schedule})
      if (this.props.schedule.saveFlag)
        this.setState({saveFlag: false})
    }
  }

  handleAddMore = () => {
    let { schedule, tableHeader, tableData} = this.state
    const maxId = Math.max.apply(Math, tableData.map(function(o) { return o.order; }))
    tableData.push({order: maxId + 1, col1: '', col2: {title: '',url: ''}})
    this.setState({tableData: tableData})
    this.setState({autofocusRow: maxId + 1})
    schedule.data.ttData.value = JSON.stringify({header: tableHeader, items: tableData})
    this.setState({schedule: schedule})
  }

  handleAddMoreBelow = (row) => {
    let { schedule, tableHeader, tableData} = this.state
    tableData.splice(row, 0, {order: row+1, col1: '', col2: {title: '',url: ''}})
    let counter = 0
    tableData = tableData.map((item)=>{
      counter++
      item.order = counter
      return item
    })
    this.setState({tableData: tableData})
    this.setState({autofocusRow: row + 1})
    schedule.data.ttData.value = JSON.stringify({header: tableHeader, items: tableData})
    this.setState({schedule: schedule})
  }

  handleScheduleSave = () => {
    let { schedule, tableHeader, tableData, pageTitle} = this.state
    this.setState({saveFlag: true})
    schedule.data.ttData.value = JSON.stringify({header: tableHeader, items: tableData})
    schedule.data.pageTitle = pageTitle
    this.props.dispatch(setSchedule(schedule.data))
    this.props.dispatch(saveSchedule(schedule))
  }

  handleDelete = (row) => {
    let { schedule, tableHeader, tableData} = this.state
    tableData = tableData.filter((item) => {
      return item.order !== row
    })
    let counter = 0
    tableData = tableData.map((item)=>{
      counter++
      item.order = counter
      return item
    })
    this.setState({tableData: tableData})
    this.setState({autofocusRow: 1})
    schedule.data.ttData.value = JSON.stringify({header: tableHeader, items: tableData})
    this.setState({schedule: schedule})
  }

  handleResourcePicker = (row) => {
    this.setState({selectedRow: row})
    this.setState({pickerOpen: true})
  }

  onResourceSelection = (resource) => {
    const {schedule, storeDomain, tableData, tableHeader, selectedRow, shop} = this.state
    const domainLink = storeDomain ? storeDomain : shop
    if (!resource.length)
      return
    const productTitle = resource[0].title
    const productURL = `https://${domainLink}/products/${resource[0].handle}`
    tableData[selectedRow - 1].col2.title = productTitle
    tableData[selectedRow - 1].col2.url = productURL
    this.setState({tableData: tableData})
    this.setState({pickerOpen: false})
    schedule.data.ttData.value = JSON.stringify({header: tableHeader, items: tableData})
  }

  render () {
    const { loading, pickerOpen, tableHeader, tableData, autofocusRow, storeDomain, shop, saveFlag, pageTitle } = this.state
    const domainLink = storeDomain ? storeDomain : shop
    return (
      <div style={{height: '100px'}}>
        { loading ? 
          <Frame>
            <Loading />
            <ScheduleSkeleton />
          </Frame>
          : <Page
            title='Manage Schedule Table'
            >
                <Stack distribution='trailing'>
                  <Link url={`https://${domainLink}/pages/tt-timetable`} external>
                    View page
                  </Link>
                </Stack>
                <ResourcePicker
                  resourceType='Product'
                  open={pickerOpen}
                  onSelection={({selection}) => {this.onResourceSelection(selection)}}
                  onCancel={() => this.setState({pickerOpen: false})}
                  allowMultiple={false}
                  showVariants={false}
                />
                <Card title="" sectioned>
                  <Form>
                    <FormLayout>
                    <Card.Section title="Page Title">
                        <TextField
                          id='pageTitle'
                          value={pageTitle}
                          type='text'
                          onChange={this.handleTitleChange()}
                        />
                    </Card.Section>
                    <Card.Section title="Table Heading">
                      <FormLayout.Group>
                        <TextField
                          id='col1H'
                          value= {tableHeader && tableHeader[0]}
                          type='text'
                          onChange={this.handleHeaderChange(0)}
                        />
                        <TextField
                          id='col2H'
                          value= {tableHeader && tableHeader[1]}
                          type='text'
                          onChange={this.handleHeaderChange(1)}
                        />
                        </FormLayout.Group>
                      </Card.Section>
                      <Card.Section title="Table Details">
                      {
                        tableData && tableData.map((item) => (
                          <FormLayout.Group key={`row-${item.order}`}>
                              <TextField
                              id={`col1-${item.order}`}
                              value= {item.col1}
                              type='text'
                              onChange={this.handleChange(item.order, 'col1')}
                              autoFocus={item.order == autofocusRow}
                            />
                            <Stack>
                              <Stack.Item fill>
                                { item.col2.title ? <TextField
                                  id={`col2-${item.order}`}
                                  value= {item.col2.title}
                                  type='text'
                                  readOnly={true}
                                /> :
                                <Button fullWidth outline onClick= {() => this.handleResourcePicker(item.order)} > Add Service / Product </Button>
                                }
                              </Stack.Item>
                              <Stack.Item>
                                <Button onClick= {() => this.handleAddMoreBelow(item.order)} icon = { AddMajorMonotone }></Button>
                              </Stack.Item>
                              <Stack.Item>
                                <Button onClick= {() => this.handleDelete(item.order)} icon = { DeleteMajorMonotone }></Button>
                              </Stack.Item>
                            </Stack>
                          </FormLayout.Group>
                        ))
                      }
                      </Card.Section>
                      <Stack distribution='trailing'>
                        <ButtonGroup>
                          <Button onClick= {() => this.handleAddMore()} >
                            Add More
                          </Button>
                          <Button primary
                            onClick= {() => this.handleScheduleSave()}
                            loading= {saveFlag}
                          >
                            Save
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </FormLayout>
                  </Form>
                </Card>
                <Stack distribution='trailing'>
                  <Link url={`https://${domainLink}/pages/tt-timetable`} external>
                    View page
                  </Link>
                </Stack>
            </Page>
        }
      </div>
    )
  }

  handleTitleChange = (field) => {
    return (value) => { 
      const { pageTitle } = this.state
      this.setState({pageTitle: value})
    }
  }

  handleHeaderChange = (field) => {
    return (value) => { 
      const { tableHeader } = this.state
      tableHeader[field]=value
      this.setState({tableHeader: tableHeader})
    }
  }

  handleChange = (row, field) => {
    return (value) => { 
      const { tableData } = this.state
      tableData[row-1][field] = value
      this.setState({tableData: tableData})
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    shopId: state.shop.id,
    loading: state.schedule.isLoading,
    schedule: state.schedule
  }
}

export default connect(mapStateToProps)(withStyles(globalStyles)(Schedule))