/**
 * Constants for notification texts. For now just Toasts
 */
export const NOTIFICATIONS = {
  PROCESSING_COMPLETED: {
    id: 'processing-complete',
    text: 'Your data has been processed',
    type: 'info'
  },
  TRACKINGDATA_ERROR: {
    id: 'trackingdata-fetch-error',
    text: 'Unable to load analytics, please refresh or contact us',
    type: 'error'
  },
  TOGGLEVIEWMODE_LIVE: {
    id: 'toggelviewmode-live-success',
    text: 'Switched to Live mode',
    type: 'info'
  },
  TOGGLEVIEWMODE_PREVIEW: {
    id: 'toggelviewmode-preview-success',
    text: 'Frankie is Paused',
    type: 'info'
  },
  TOGGLEVIEWMODE_ERROR: {
    id: 'togglemode-api-error',
    text: 'Unable to change view mode, please refresh or contact us',
    type: 'error'
  },
  SETTINGS_SAVED: {
    id: 'settings-save-success',
    text: 'Schedule saved',
    type: 'info'
  },
  SETTINGS_ERROR: {
    id: 'settings-save-error',
    text: 'Schedule not saved, please try again or contact us',
    type: 'error'
  },
  KLAVIYO_SETTINGS_SAVED: {
    id: 'klaviyo-settings-save-success',
    text: 'Style saved. Copy the new template.',
    type: 'info'
  },
  CONTACT_SAVED: {
    id: 'contact-save-success',
    text: 'Contact saved.',
    type: 'info'
  },
  CONTACT_ERROR: {
    id: 'contact-not-save-success',
    text: 'There was an issue in saving, please try again.',
    type: 'error'
  },
  CUSTOMDIV_COPIED: {
    id: 'customdiv-html-success',
    text: 'Copied!',
    type: 'info'
  },
  PROMO_APPLIED: {
    id: 'promo-save-success',
    text: 'Promo code applied',
    type: 'info'
  },
  PROMO_ERROR: {
    id: 'promo-save-error',
    text: 'Promo code not applied',
    type: 'error'
  },
  CSV_ERROR: {
    id: 'csv-export-error',
    text: 'There was an issue exporting, please try again',
    type: 'error'
  },
  CSV_SUCCESS: {
    id: 'csv-export-success',
    text: 'Contacts exported, use the Download link to save',
    type: 'info'
  },
  CSV_NOT_FOUND: {
    id: 'csv-export-not-found',
    text: 'No contacts found in the selected date range',
    type: 'info'
  }
}