import { addNotificationById } from './notifications'
import { NOTIFICATIONS } from '../../_constants/notifications'
import hipleeApiService from '../../services/hipleeApi'

export const SET_SCHEDULE        = 'SET_SCHEDULE'
export const FETCH_SCHEDULE      = 'FETCH_SCHEDULE'
export const SAVE_SCHEDULE       = 'SAVE_SCHEDULE'

/**
 * Static set of schedule into store
 */
export const setSchedule = data => ({
  type: SET_SCHEDULE,
  payload: { data: data }
})

const fetchScheduleBegin = () => ({
  type: FETCH_SCHEDULE,
  payload: { isLoading: true }
})

const fetchScheduleSuccess = data => ({
  type: FETCH_SCHEDULE,
  payload: { isLoading: false, unsavedChanges: false, status: 'success', data }
})

const fetchScheduleFailure = error => ({
  type: FETCH_SCHEDULE,
  payload: { isLoading: false, unsavedChanges: true, status: 'error', error }
})

/**
 * Lines up merchant settings loaded from the API,
 * and pricing plan loaded dependant on merchant settings
 *
 * Only load it once, if the settings are already in state there's no need to call this again.
 */
export const fetchSchedule = () => {
  return (dispatch, getState) => {
    dispatch(fetchScheduleBegin())
    hipleeApiService.getSchedule(getState().token)
      .then(response => response.json())
      .then(response => {
        if (!response.pageId) {
          dispatch(fetchScheduleFailure('Couldn\'t fetch schedule'))
          return
        }

        dispatch(fetchScheduleSuccess(response))
      })
      .catch(error => {
        dispatch(fetchScheduleFailure(error))
      })
  }
}


const saveScheduleBegin = () => ({
  type: SAVE_SCHEDULE,
  payload: { isLoading: true }
})

const saveScheduleSuccess = () => ({
  type: SAVE_SCHEDULE,
  payload: { isLoading: false, status: 'success' }
})

const saveScheduleFailure = error => ({
  type: SAVE_SCHEDULE,
  payload: { isLoading: false, status: 'error', error }
})

export const saveSchedule = (schedule, savedNotification = addNotificationById(NOTIFICATIONS.SETTINGS_SAVED.id)) => {  
  return (dispatch, getState) => {
    dispatch(saveScheduleBegin())
    hipleeApiService.putSchedule(getState().token, schedule.data)
      .then(response => {
        dispatch(saveScheduleSuccess())
        dispatch(savedNotification)
      })
      .catch(error => {
        console.log(error)
        dispatch(saveScheduleFailure(error))
        dispatch(addNotificationById(NOTIFICATIONS.SETTINGS_ERROR.id))
      })
  }
}