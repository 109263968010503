
import { combineReducers } from 'redux'
import { token } from './token'
import { notifications } from './notifications'
import { shop } from './shop'
import { schedule } from './schedule'

// TODO: maybe eventually do this https://redux.js.org/basics/reducers#note-for-es6-savvy-users
const reducers = combineReducers({
  token,
  notifications,
  shop,
  schedule
})

export default reducers