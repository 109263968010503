import React, { Fragment, Component } from 'react'
import FullStory from 'react-fullstory'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { TitleBar } from '@shopify/app-bridge-react'
import { Provider } from 'react-redux'
import store from '../_redux/store'
import { fetchSchedule } from '../_redux/actions/schedule'
import { setToken } from '../_redux/actions/token'
import { _fullStory, _fbpixel, _gtm } from './../_constants/globals'
import hipleeApiService from './../services/hipleeApi'
import { getUrlParameter, getShopDomain } from './../services/window'
import { scrollToTheTopPlease } from './../utilities/windowHandler'
import { Frame } from '@shopify/polaris'
import { Loading } from '@shopify/app-bridge-react'
import Schedule from '../containers/Schedule/Schedule'
import OnboardingGuide from './../containers/OnboardingGuide/Welcome'
import LearnMore from './../containers/LearnMore/LearnMore'
import FatalErrorPage from './../containers/FatalErrorPage/FatalErrorPage'
import InMaintenancePage from './../containers/InMaintenancePage/InMaintenancePage'
import ToastGenerator from '../components/ToastGenerator/ToastGenerator'

/**
 * Main React component that loads the whole App or the Onboarding guide
 */
class App extends Component {
  constructor(props) {
    super(props)

    const hasFatalError = getUrlParameter('fatalError')
    const inMaintenance = getUrlParameter('inMaintenance')
    const justInstalled = getUrlParameter('install')
    const storeDomain = getUrlParameter('sd')
    const chargeRedirect = getUrlParameter('cd')

    this.state = {
      isLoading: true,
      processingStatus: null,
      needsOnboarding: justInstalled,
      hasFatalError,
      inMaintenance,
      justInstalled,
      storeDomain,
      pageDisplay: 'sch'
    }

    if (chargeRedirect) {
      window.top.location.href = decodeURIComponent(chargeRedirect)
      return
    }

    if (!hasFatalError && !inMaintenance && !chargeRedirect)
      this.initializeShop(justInstalled)
  }

  /**
   *
   */
  initializeShop = async (justInstalled) => {
    try {
      // Query string params passed from Shopify to the "redirectToCallbackRoute" lambda to the s3 url
      // are used here to authenticate with our API and get a JWT.
      // This JWT can be used to authorize future requests to our API.
      const authParams = window.location.search
      const authTokenResponse = await hipleeApiService.getAuthToken(authParams)
      if (!authTokenResponse.ok)
        throw Error(authTokenResponse.statusText)
      const jwtToken = await authTokenResponse.text()

      store.dispatch(setToken(jwtToken))
      store.dispatch(fetchSchedule())
      // add facebook pixel
      if (_fbpixel) {
        ReactPixel.init(_fbpixel)
        ReactPixel.pageView()
      }
      // add GTM
      if (_gtm) {
        const tagManagerArgs = {
          gtmId: _gtm,
          dataLayer: {
            shopId: getShopDomain(),
            event: 'appLoaded'
          }
        }
        TagManager.initialize(tagManagerArgs)
      }
      this.setState({
        isLoading: false,
        needsOnboarding: justInstalled
      })
      // No further action required
      if (!justInstalled)
        return

      // update GTM data layer for new installs
      if (_gtm) {
        const tagManagerArgs = {
          dataLayer: {
            shopId: getShopDomain(),
            event: 'newInstall'
          }
        }
        TagManager.initialize(tagManagerArgs)
      }
    } catch (error) {
      // if something went horribly wrong we should at least display a "something is broken" screen
      this.setState({ hasFatalError: true })

      if (window.location.hostname === 'localhost') {
        console.log(error)
      }
    }
  }

  /**
   * Close the onboarding guide.
   * Call the store info lambda again, with any luck the processing will be done by now
   *
   * This would probably get into a race condition if we don't await for `getStoreInfo`,
   * and there might be a chance that we get here before the store has been picked for processingbetter safe than sorry
   */
  handleOnboardingComplete = async (learnMore) => {
    this.setState({ needsOnboarding: false })
    learnMore && this.setState({ pageDisplay: 'lm' })
    scrollToTheTopPlease()
  }

  handleRedoOnboarding = () => {
    this.setState({ needsOnboarding: true })
  }

  handlePageView = async (page) => {
    this.setState({ pageDisplay: page })
    scrollToTheTopPlease()
  }
  /**
   * Render either the app or onboarding
   */
  render() {
    const { isLoading, needsOnboarding, hasFatalError, inMaintenance  } = this.state
    const secondaryActions = [
                              {content: 'Create / Update Schedule', onAction: () => this.handlePageView('sch')},
                              {content: 'Learn More', onAction: () => this.handlePageView('lm')}
                            ]
    if (hasFatalError)
      return <FatalErrorPage />

    if (inMaintenance)
      return <InMaintenancePage />

    if (needsOnboarding)
      return <OnboardingGuide onComplete={ this.handleOnboardingComplete } />

    let content
    switch (this.state.pageDisplay) {
      case 'sch': {
        content = <Schedule storeDomain={this.state.storeDomain}/>
        break
      }
      case 'lm': {
        content = <LearnMore />
        break
      }
      default: {
        content = <Schedule storeDomain={this.state.storeDomain}/>
      }
    }
    return (
      <Fragment>
        { _fullStory && <FullStory org={ _fullStory } /> }
        <Provider store={ store }>
          <TitleBar
            title=""            
            secondaryActions={secondaryActions}
          />
          {/* Global loading state */}
          { isLoading &&
            <Frame>
              <Loading />
            </Frame>
          }

          { !needsOnboarding && content }

          <ToastGenerator />
        </Provider>
      </Fragment>
    )
  }
}

export default App